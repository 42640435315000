import React, { useEffect, useState } from "react";
import _ from "lodash";

import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import CancelIcon from "@material-ui/icons/Block";

import LoadingContainer from "components/Loading/LoadingContainer";
import CurrencyService from "services/CurrencyService";
import Field from "components/Layout/Field";
import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";

const defaultCurrencyState = {
  code: "",
  label: "",
  exchange_rate: "1.00",
  sorting: 0,
};

const AddCurrencyDialog = (props) => {
  const { open, onSave, onClose } = props;

  const [{ code, label, exchange_rate, sorting }, setState] =
    useState(defaultCurrencyState);

  console.log(sorting);

  const [errors, setErrors] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleClose = () => {
    setState(defaultCurrencyState);
    setErrors(null);
    onClose && onClose();
  };

  const handleSaveAndExit = async () => {
    if (await handleSaveAndContinue()) {
      onClose && onClose();
    }
  };

  const handleSaveAndContinue = async () => {
    setWaiting(true);

    const currencyData = await CurrencyService.addCurrency(
      code,
      label,
      exchange_rate,
      sorting
    );

    setWaiting(false);

    if (currencyData.errors) {
      setErrors(currencyData.errors);
      return false;
    } else {
      onSave && onSave(currencyData);
      setErrors(null);
      setState(defaultCurrencyState);
      return true;
    }
  };

  return (
    <Dialog open={open}>
      {waiting && <LoadingContainer />}
      <DialogTitle>Add New Region</DialogTitle>
      <DialogContent>
        <Field style={{ whiteSpace: "pre-wrap", marginTop: "5px" }}>
          The currency <i>code</i> must be an official ISO currency code. They
          can be looked up{" "}
          <a
            target='__blank'
            href='https://en.wikipedia.org/wiki/ISO_4217#Active_codes'
          >
            here
          </a>
          .
        </Field>
        <Field>
          <TextInput
            inputProps={{ autoFocus: true }}
            label='Currency Code'
            value={code}
            name='code'
            onChange={handleChange}
            error={errors?.code}
            errorMessage={errors?.code}
          />
        </Field>
        <Field>
          <TextInput
            label='Label'
            value={label}
            name='label'
            onChange={handleChange}
            error={errors?.label}
            errorMessage={errors?.label}
          />
        </Field>
        <Field>
          <TextInput
            label='Exchange Rate'
            value={exchange_rate}
            name='exchange_rate'
            onChange={handleChange}
            type='number'
            error={errors?.exchange_rate}
            errorMessage={errors?.exchange_rate}
          />
        </Field>
        <Field>
          <TextInput
            label='Sorting Index'
            value={sorting}
            name='sorting'
            onChange={handleChange}
            type='number'
            error={errors?.sorting}
            errorMessage={errors?.sorting}
          />
        </Field>
      </DialogContent>
      <DialogActions>
        <Button disabled={waiting} color='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={waiting}
          color='primary'
          onClick={handleSaveAndContinue}
        >
          Save &amp; Add Another
        </Button>
        <Button disabled={waiting} color='primary' onClick={handleSaveAndExit}>
          Save &amp; Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CurrencyEntry = (props) => {
  const {
    readOnly = false,
    data: { currency_id, code, label, exchange_rate, sorting },
    onSave,
    onDelete,
  } = props;

  const [state, setState] = useState({
    code,
    label,
    exchange_rate,
    sorting,
    editMode: false,
    waiting: false,
  });

  const [errors, setErrors] = useState(null);

  const enableEditMode = () =>
    !readOnly && setState((orig) => ({ ...orig, editMode: true }));
  const cancelEditMode = () =>
    setState({ code, label, exchange_rate, editMode: false });

  const handleChange = ({ target: { name, value } }) => {
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleSave = async () => {
    setState((orig) => ({ ...orig, waiting: true }));
    const savedData = await CurrencyService.saveCurrency(
      currency_id,
      state.code,
      state.label,
      state.exchange_rate,
      state.sorting
    );

    if (savedData.errors) {
      setErrors(savedData.errors);
      setState((orig) => ({ ...orig, waiting: false }));
    } else {
      onSave && onSave(savedData);
      setState({
        code: savedData.code || "",
        label: savedData.label || "",
        exchange_rate: savedData.exchange_rate || "",
        sorting: savedData.sorting || "",
        editMode: false,
        waiting: false,
      });
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm(`Are you sure you want to delete the currency: ${code}?`)
    ) {
      setState((orig) => ({ ...orig, waiting: true }));
      await CurrencyService.deleteCurrency(currency_id);
      onDelete && onDelete();
    }
  };

  if (!readOnly && state.editMode) {
    return (
      <TableRow style={{ position: "relative" }}>
        <TableCell>
          <TextInput
            name='code'
            onChange={handleChange}
            value={state.code}
            error={errors?.code}
            errorMessage={errors?.code}
          />
        </TableCell>
        <TableCell>
          <TextInput
            name='label'
            onChange={handleChange}
            value={state.label}
            error={errors?.label}
            errorMessage={errors?.label}
          />
        </TableCell>
        <TableCell>
          <TextInput
            name='exchange_rate'
            onChange={handleChange}
            value={state.exchange_rate}
            error={errors?.exchange_rate}
            errorMessage={errors?.exchange_rate}
            type='number'
          />
        </TableCell>
        <TableCell>
          <TextInput
            name='sorting'
            onChange={handleChange}
            value={state.sorting}
            error={errors?.sorting}
            errorMessage={errors?.sorting}
            type='number'
          />
        </TableCell>
        <TableCell>
          {state.waiting && <LoadingContainer />}
          <ButtonGroup>
            <Button disabled={state.waiting} onClick={handleSave}>
              <SaveIcon style={{ color: "green" }} />
            </Button>
            <Button disabled={state.waiting} onClick={cancelEditMode}>
              <CancelIcon style={{ color: "red" }} />
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow
        style={{ position: "relative", color: readOnly ? "grey" : undefined }}
      >
        <TableCell style={{ color: "inherit" }}>{state.code}</TableCell>
        <TableCell style={{ color: "inherit" }}>{state.label}</TableCell>
        <TableCell style={{ color: "inherit" }}>
          {state.exchange_rate}
        </TableCell>
        <TableCell style={{ color: "inherit" }}>{state.sorting}</TableCell>
        {readOnly ? (
          <TableCell style={{ textAlign: "right", color: "inherit" }}>
            Base currency
            <br />
            Cannot be edited
          </TableCell>
        ) : (
          <TableCell style={{ textAlign: "right", color: "inherit" }}>
            {state.waiting && <LoadingContainer />}
            <ButtonGroup>
              <Button disabled={state.waiting} onClick={enableEditMode}>
                <EditIcon />
              </Button>
              <Button disabled={state.waiting} onClick={handleDelete}>
                <DeleteIcon style={{ color: "red" }} />
              </Button>
            </ButtonGroup>
          </TableCell>
        )}
      </TableRow>
    );
  }
};

const CurrencyView = (props) => {
  const [currencies, setCurrencies] = useState(null);
  const [showingDialog, setDialog] = useState(-1);
  const [waiting, setWaiting] = useState(false);

  const loadCurrencies = async () => {
    const regions = await CurrencyService.getCurrencies();
    setCurrencies(regions);
  };

  const showAddDialog = () => {
    setDialog(1);
  };
  const closeDialog = () => {
    setDialog(-1);
  };

  const currencyAdded = () => {
    setCurrencies(null);
    loadCurrencies();
  };

  const handleUpdate =
    (currency_id) =>
    (e, { code, label, exchange_rate }) => {
      setCurrencies((orig) =>
        _.map(orig, (currency) =>
          currency.currency_id === currency_id
            ? { ...currency, code, label, exchange_rate }
            : currency
        )
      );
    };

  const handleDelete = (currency_id) => () => {
    setCurrencies((orig) =>
      _.filter(orig, (currency) => currency.currency_id !== currency_id)
    );
  };

  const handleDeleteAll = async () => {
    if (
      window.confirm(
        "Are you sure want to remove ALL currencies? (This action is not reversible)"
      )
    ) {
      setWaiting(true);
      await CurrencyService.deleteAll();
      setCurrencies([]);
      setWaiting(false);
    }
  };

  const mount = () => {
    loadCurrencies();
  };
  useEffect(mount, []);

  return (
    <div style={{ width: "100%" }}>
      {(!currencies || waiting) && <LoadingContainer />}
      <ButtonGroup>
        <Button variant='contained' color='primary' onClick={showAddDialog}>
          Add
        </Button>
        <Button variant='contained' color='secondary' onClick={handleDeleteAll}>
          Delete All
        </Button>
      </ButtonGroup>
      <div style={{ marginTop: "8px" }}>Note:</div>
      <ul style={{ whiteSpace: "pre-wrap" }}>
        <li>
          The <i>exchange rate</i> is relative to USD
        </li>
        <li>
          The currency <i>code</i> must be an official ISO currency code. They
          can be looked up{" "}
          <a
            target='__blank'
            href='https://en.wikipedia.org/wiki/ISO_4217#Active_codes'
          >
            here
          </a>
          .
        </li>
        <li>
          Currencies are displayed in order of lowest to highest{" "}
          <i>sorting index</i>
        </li>
      </ul>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Code</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Label</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Exchange Rate
              </TableCell>
              <TableCell colSpan={2} style={{ fontWeight: "bold" }}>
                Sorting Index
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <CurrencyEntry
              data={{
                code: "usd",
                label: "USD",
                exchange_rate: "1.00",
                sorting: 0,
              }}
              readOnly
            />
            {!currencies || currencies.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} color='grey'>
                  Currency list empty
                </TableCell>
              </TableRow>
            ) : (
              currencies.map((currency) => (
                <CurrencyEntry
                  key={currency.currency_id}
                  data={currency}
                  onDelete={handleDelete(currency.currency_id)}
                  onUpdate={handleUpdate(currency.currency_id)}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddCurrencyDialog
        open={showingDialog === 1}
        onClose={closeDialog}
        onSave={currencyAdded}
      />
    </div>
  );
};

export default CurrencyView;
