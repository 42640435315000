import React, { useContext } from "react";
import styled from "styled-components";

import { H3 } from "components/LoginView/Headings";
import { AppContext } from "context/AppContext";
import { AccountContext } from "context/AccountContext";
import { ShopContext } from "context/ShopContext";

const PriceTable = styled.table`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  border-collapse: collapse;
`;

const TR = styled.tr`
  ${({ lineBreak }) =>
    lineBreak &&
    `
    border-top: 1px solid grey;
  `}
`;

const PriceWarning = styled.div`
  position: relative;

  font-size: 12px;
  margin-bottom: 8px;

  padding-left: 10px;

  &:before {
    content: "*";
    position: absolute;
    left: 0;
  }
`;

const OrderOverview = (props) => {
  const { formatPrice } = useContext(AppContext);
  const { subscription } = useContext(AccountContext);

  const { currentOrder, bundlePreview, isUpgrade } = useContext(ShopContext);

  const order = (() => {
    if (isUpgrade) {
      return bundlePreview;
    } else if (currentOrder) {
      return currentOrder;
    } else {
      return {};
    }
  })();

  const {
    price,
    credit,
    coupon_discount,
    starting_balance,
    ending_balance,
    next_billing_date,
    adjusted_price,
  } = order || {};

  const isRenewal =
    subscription?.status === "active" &&
    subscription?.product_id === currentOrder?.product_id;

  return (
    <React.Fragment>
      {isUpgrade && !isRenewal && (
        <React.Fragment>
          <H3 style={{ textAlign: "left" }}>What happens next</H3>
          <ul>
            <li>Your new plan starts today.</li>
            <li>
              {subscription?.renewal_type === "m"
                ? `Your subscription will be active until ${next_billing_date}.`
                : `Starting ${next_billing_date}, you will be charged ${formatPrice(
                    price
                  )}.`}
            </li>
            <li>
              We credit the value of your current plan to Your Credit. Your
              Credit is used for future payments.
            </li>
          </ul>
        </React.Fragment>
      )}
      <H3 style={{ textAlign: "left" }}>Order Summary</H3>
      <PriceTable>
        <tbody>
          <TR>
            <td>{isRenewal ? "Renew your plan" : "Your new plan"}</td>
            <td style={{ textAlign: "right" }}>{formatPrice(price)}</td>
          </TR>
          {!!starting_balance && parseFloat(starting_balance.amount) !== 0 && (
            <TR style={{ color: starting_balance > 0 ? "black" : "green" }}>
              <td>Current Balance</td>
              <td style={{ textAlign: "right" }}>
                {formatPrice(starting_balance)}
              </td>
            </TR>
          )}
          {!!credit && parseFloat(credit.amount) !== 0 && (
            <TR style={{ color: "green" }}>
              <td>Your Credit</td>
              <td style={{ textAlign: "right" }}>{formatPrice(credit)}</td>
            </TR>
          )}
          <TR lineBreak />
          {!!coupon_discount && (
            <TR style={{ color: "green" }}>
              <td>
                Coupon
                {coupon_discount.coupon
                  ? `: ${coupon_discount.coupon}`
                  : " Code"}
              </td>
              <td style={{ textAlign: "right" }}>
                &ndash;{formatPrice(coupon_discount.discount)}
              </td>
            </TR>
          )}
          <TR>
            <td>TOTAL</td>
            <td style={{ textAlign: "right" }}>
              {formatPrice(
                typeof adjusted_price === "undefined" ? price : adjusted_price
              )}
            </td>
          </TR>
          {((!!ending_balance && parseFloat(ending_balance.amount) !== 0) ||
            (!!starting_balance &&
              parseFloat(starting_balance.amount) !== 0)) && (
            <TR>
              <td>Ending Balance</td>
              <td style={{ textAlign: "right" }}>
                {formatPrice(ending_balance)}
              </td>
            </TR>
          )}
        </tbody>
      </PriceTable>
      {adjusted_price > 0 && adjusted_price <= 50 ? (
        <PriceWarning>
          Totals less than $0.50 are not charged until the next payment date.
        </PriceWarning>
      ) : null}
    </React.Fragment>
  );
};

export default OrderOverview;
